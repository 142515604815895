import { getToken } from "@/utils/auth";
import { Message } from "element-ui";
import store from "../store";
import router from "../router";
import { recordVisit } from "@/api/home";
const whiteList = ["/login"];
router.beforeEach((to, from, next) => {
  if (getToken()) {
    /* has token*/
    if (to.path === "/login") {
      next({ path: "/index" });
      // next();
    } else {
      if (store.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        store
          .dispatch("GetInfo")
          .then(() => {
            next();
          })
          .catch((err) => {
            store.dispatch("LogOut").then(() => {
              Message.error("permission", err);
              next({ path: "/index" });
            });
          });
      } else {
        next();
      }
    }
  } else {
    // 没有token
    if(to.name != "login"){
      store
              .dispatch("ipLogin")
              .then((res) => {
                if(res.token){
                  setTimeout(() => {
                    next({ path: "/index" });
                  }, 100);
                }else{
                  next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
                }
                // 
              })
              .catch((error) => {
                next();
              });
    }else{
      next()
    }
     
    // if (whiteList.indexOf(to.path) !== -1) {
    //   if (
    //     to.name != "login" &&
    //     (to.query.loginFlag == undefined || to.query.loginFlag != "1")
    //   ) {
    //     store
    //       .dispatch("ipLogin")
    //       .then((res) => {
    //         next({ path: "/index" });
    //       })
    //       .catch((error) => {
    //         next();
    //       });
    //   } else {
    //     // 在免登录白名单，直接进入
    //     next();
    //   }
    // } else {
    //   // store
    //   //     .dispatch("ipLogin")
    //   //     .then((res) => {
    //   //       next({ path: "/index" });
    //   //     })
    //   //     .catch((error) => {
    //   //       next();
    //   //     });
    //   next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
    //   next();
    // }
  }
});

router.afterEach((to, from) => {
  let pages = to.name;
  let pageType = "UNKNOWN";
  if (to.path == "/index" || to.path == "/") {
    pageType = "INDEX";
    // 首页
    recordVisit(pages, pageType).then((res) => {
      // console.log(res)
    });
  } else if (to.path == "/classify" || to.path == "/search") {
    // 列表
    pageType = "LIST";
    // 首页
    recordVisit(pages, pageType).then((res) => {
      // console.log(res)
    });
  } else if (to.path == "/detail") {
    // 详情
    pageType = "DETAIL";
    // 首页
    recordVisit(pages, pageType).then((res) => {
      // console.log(res)
    });
  } else if (to.path == "/highSearch") {
    // 高级检索
    pageType = "SENIOR_SEARCH";
    // 首页
    recordVisit(pages, pageType).then((res) => {
      // console.log(res)
    });
  } else {
    // 未知
    pageType = "UNKNOWN";
  }
});
