<template>
  <div class="container" v-if="path != 'pdf'">
    <p class="mb15" style="opacity: 0.5">
      {{ footerForm.configValue }}
      <!-- 化学工业出版社有限公司 版权所有 |
      联系电话：010-64519105（座机）；18600895080（手机） -->
    </p>
    <p>
      <span style="opacity: 0.5" @click="handleOpenLicence" class="linkurl"
        >网络出版服务许可证</span
      >
      <img
        style="vertical-align: middle; margin-right: 5px"
        src="@/assets/images/wj.png"
      />
      <span style="opacity: 0.5" @click="handleOpenArchival(1)" class="linkurl"
        >京公网安备 11010102002361号</span
      >
      <span style="opacity: 0.5" @click="handleOpenArchival(2)" class="linkurl"
        >京ICP备12046843号-10</span
      >
    </p>
  </div>
</template>

<script>
import licenseImg from "@/assets/images/wlcbfwxkz.jpg";
import { list } from "@/api/setting.js";
export default {
  name: "bottom",
  data() {
    return {
      path: "",
      footerForm:
        "化学工业出版社有限公司 版权所有 |  联系电话：010-64519105（座机）；18600895080（手机）",
    };
  },
  watch: {
    $route: {
      handler(val) {
        this.path = val.name;
      },
      immediate: true,
    },
  },

  computed: {},
  created() {
    this.getDetailByType("sys.footer.info");
  },
  mounted() {},
  methods: {
    // 获取页脚详情
    getDetailByType(type) {
      list(type).then(res => {
        if (type == "sys.footer.info") {
          this.footerForm = res.rows[0];
        }
      });
    },
    // 打开网络许可证
    handleOpenLicence() {
      window.open(licenseImg);
    },
    // 打开备案信息
    handleOpenArchival(type) {
      if (type == 1) {
        window.open(
          "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102002361"
        );
      } else {
        window.open("https://beian.miit.gov.cn/");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.linkurl {
  cursor: pointer;
  text-decoration: underline;
  margin-right: 15px;

  &:hover {
    color: #00b8ff;
    text-decoration: underline;
  }
}
.container {
  border-top: 2px solid #078bef;
  background: #333333;
  color: #999999;
  text-align: center;
  padding: 23px 0;
  font-size: 14px;
}
</style>
