import "babel-polyfill";
// import 'core-js/stable';
// import 'regenerator-runtime/runtime';
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "./styles/reset.scss";
import "./styles/init.scss";
import "./styles/_variables.scss";
import "element-ui/lib/theme-chalk/index.css";
import "../element-variables.scss";
import "./utils/permission"; // permission control
import "./assets/fonts/iconfont.css";
import axios from "axios";
Vue.prototype.$axios = axios;
axios.defaults.withCredentials = true;

// import Sticky from 'vue-sticky-directive'
import { handleTree, getPathByKey, treeFind } from "@/utils/cxstar";
Vue.prototype.handleTree = handleTree;
Vue.prototype.treeFind = treeFind;
Vue.prototype.getPathByKey = getPathByKey;


// Vue.use(Sticky)
// 引入swiper样式
import "swiper/dist/css/swiper.css";
//挂载swiper
Vue.use(ElementUI);

import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";
Vue.use(Viewer);
Viewer.setDefaults({
  Options: {
    inline: true, // 启用 inline 模式
    button: true, // 显示右上角关闭按钮
    navbar: true, // 显示缩略图导航
    title: true, // 显示当前图片的标题
    toolbar: true, // 显示工具栏
    tooltip: true, // 显示缩放百分比
    movable: true, // 图片是否可移动
    zoomable: true, // 图片是否可缩放
    rotatable: true, // 图片是否可旋转
    scalable: true, // 图片是否可翻转
    transition: true, // 使用 CSS3 过度
    fullscreen: true, // 播放时是否全屏
    keyboard: true, // 是否支持键盘
    url: "data-source", // 设置大图片的 url
  },
});

import fetch from "./api/axios";
Vue.prototype.$http = fetch;
Vue.config.productionTip = false;
// 全局配置弹框的蒙层点击不消失
ElementUI.Dialog.props.closeOnClickModal.default = false;
ElementUI.MessageBox.setDefaults({
  closeOnClickModal: false,
});
// 全局配置提示信息Message
const messages = ["success", "warning", "info", "error"];

messages.forEach((type) => {
  ElementUI.Message[type] = (options) => {
    if (typeof options === "string") {
      options = {
        message: `${options}`,
      };
      // 默认配置
      options.dangerouslyUseHTMLString = true;
      options.offset = 400;
      options.duration = 1500;
      options.showClose = false;
      // options.center = true;
    }
    options.type = type;
    return ElementUI.Message(options);
  };
});
new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this; //安装全局事件总线
  },
}).$mount("#app");
