import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'

Vue.use(VueRouter)

const routes = [
	{
		path: '/index',
		name: 'index',
		component: Home,
		meta: {
			keepAlive: true // 需要被缓存
		}
	},
	{
		path: '/',
		redirect: '/index'
	},
	
	{
		path: '/classify',
		name: 'classify',
		component: () => import('@/views/Classify'),
		meta: {
			title: '图书分类'
		},
	},
	{
		path: '/detail',
		name: 'detail',
		component: () => import('@/views/ClassifyDetail'),
		meta: {
			title: '详情'
		},
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login'),
		meta: {
			title: '登录'
		},
	},
	{
		path: '/search',
		name: 'search',
		component: () => import('@/views/SearchResult'),
		meta: {
			title: '检索结果'
		},
	},
	{
		path: '/highSearch',
		name: 'highSearch',
		component: () => import('@/views/HighSearch'),
		meta: {
			title: '高级检索'
		},
	},
	{
		path: '/pdf',
		name: 'pdf',
		component: () => import('@/views/Pdf'),
		meta: {
			title: '高级检索'
		},
	},
	
	
]
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router
