import request from "@/api/axios";
// 详情
export function list(data) {
  return request({
    url: "/api/v1/system/config/openList?configKey=" + data,
    method: "get",
  });
}
// 保存
export function config(data) {
  return request({
    url: "/api/v1/system/config",
    method: "put",
    data: data,
  });
}
