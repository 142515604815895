import { login, logout, getInfo, ipLogin } from "@/api/login";
import { getToken, setToken, removeToken } from "@/utils/auth";

const searchParam = {
  state: {
    searchWord: "", //检索词
    searchBarWord: "", //检索条-检索词 字段默认为题名、作者、ISBN
    searchField: "title,author", //检索字段，先固定值
    subjectIds: [], //左侧学科
    clcIds: [], //左侧中图法
    publishYear: null, //出版年
    pageNum: 1, //页码 1
    pageSize: 10, //页面大小 10
    orderByColumn: "state_time", //排序字段 默认出版日期
    isAsc: "desc", //排序方式 默降序排序,
    searchItemList: [],
    searchNames: {},
    topArray: [],
    yearIndex: null,
    yearMore: false,
    addIndex: 1,
    publishYearStart: null,
    publishYearEnd: null,
  },

  mutations: {
    SET_FIELD: (state, field) => {
      Object.keys(field).forEach((key) => {
        state[key] = field[key];
      });
    },
    SET_LEFT: (state, field) => {
      Object.keys(field).forEach((key) => {
        state.searchNames[key] = field[key];
      });
    },
    TOP_ARRAY: (state, field) => {
      state.topArray = field;
    },
    UPDATE_FIELD: (state, obj) => {
      state[obj.key] = obj.value;
    },
    RESET_FIELD: (state, field) => {
      state.searchWord = "";
      state.searchBarWord = "";
      state.searchField = "title,author";
      state.subjectIds = [];
      state.clcIds = [];
      state.publishYear = null;
      state.publishYearStart = null;
      state.publishYearEnd = null;
      state.pageNum = 1;
      state.pageSize = 10;
      state.orderByColumn = "state_time";
      state.isAsc = "desc";
      state.searchItemList = [];
      state.searchNames = {};
      state.topArray = [];
      state.yearIndex = null;
      state.yearMore = false;
      state.addIndex = 1;
    },
  },

  actions: {
    // 设置左侧聚合参数名称
    setLeft({ commit }, field) {
      commit("SET_LEFT", field);
    },
    // 设置top组件 左侧聚合选中状态
    topArray({ commit }, field) {
      commit("TOP_ARRAR", field);
    },
    // 设置全部参数
    setAllField({ commit }, field) {
      commit("SET_FIELD", field);
    },
    // 修改参数
    updateField({ commit }, obj) {
      commit("UPDATE_FIELD", obj);
    },
    // 重置参数
    resetField({ commit }) {
      commit("RESET_FIELD");
    },
  },
};

export default searchParam;
