<template>
  <div class="container">
    <div class="wapperbg" v-if="classifyFlag" @mouseout="classifyOut"></div>
    <div class="w1200 flex" v-if="path != 'pdf'">
      <img class="pointer" @click="toHome" src="../assets/images/logo01.png" alt="" />
      <div class="flex list">
        <div
          v-if="false && ($route.path == '/' || $route.path == '/index')"
          class="flex pointer hoverTouch"
          :class="{ checked: classifyFlag }"
          @mouseenter="classifyHover"
          @mouseleave="classifyFlag = false"
        >
          <img :src="!classifyFlag ? classifyImg : classifyImged" />图书分类
        </div>
        <div
          @mouseenter="tcimg = tcselect"
          @mouseleave="tcimg = tcdefault"
          @click="logout"
          class="flex pointer ml40 tcdiv"
        >
          <img :src="tcimg" />{{ token == undefined ? "登录" : "退出" }}
        </div>
      </div>
    </div>
    <!-- 分类 @mouseout="classifyOut" -->
    <div
      class="classify"
      v-show="classifyFlag"
      @mouseenter="classifyHover"
      @mouseleave="classifyFlag = false"
    >
      <div class="w1200">
        <ul>
          <li
            class="flex oneClass"
            @click.self="handleClick(item, `全部 > ${item.name}`, 0, index)"
            @mouseenter="changeImg(item.name, index)"
            @mouseleave="outImg(item.name, index)"
            v-for="(item, index) in classifyList"
            :key="index"
          >
            <!-- <img :src="getImg(item.name)"/>{{item.name}} -->
            <img :src="getImage(item.name, index)" />
            {{ item.name }}
            <div class="twoClass">
              <div class="twoClassContent">
                <div
                  class="two-item"
                  v-for="(element, idx) in item.children"
                  :key="idx"
                  @click.self.stop="
                    handleClick(
                      element,
                      `全部 > ${item.name} > ${element.name}`,
                      0,
                      index,
                      idx
                    )
                  "
                >
                  {{ element.name }}
                  <div class="threeClass">
                    <div class="flex">
                      <span
                        @click.stop="
                          handleClick(
                            list,
                            `全部 > ${item.name} > ${element.name} > ${list.name}`,
                            0,
                            index,
                            idx,
                            threeidx
                          )
                        "
                        v-for="(list, threeidx) in element.children"
                        :key="threeidx"
                        >{{ list.name }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { indexSubjectList } from "@/api/home.js";
import { handleTree } from "../utils/cxstar";
import tcdefault from "@/assets/images/icon-out-default.png";
import tcselect from "@/assets/images/icon-out-select.png";
import { getToken } from "../utils/auth";
export default {
  name: "top",
  data() {
    return {
      token: getToken(),
      tcdefault,
      tcselect,
      tcimg: tcdefault,
      classifyFlag: false,
      classifyList: [],
      keji: require("@/assets/images/icon-keji-default.png"),
      kejied: require("@/assets/images/icon-keji-select.png"),
      jiaocai: require("@/assets/images/icon-jiaocai-default.png"),
      jiaocaied: require("@/assets/images/icon-jiaocai-select.png"),
      shenghuo: require("@/assets/images/icon-shenghuo-default.png"),
      shenghuoed: require("@/assets/images/icon-shenghuo-select.png"),
      shaoer: require("@/assets/images/icon-shaoer-default.png"),
      shaoered: require("@/assets/images/icon-shaoer-select.png"),
      sheke: require("@/assets/images/icon-sheke-default.png"),
      shekeed: require("@/assets/images/icon-sheke-select.png"),
      wenyi: require("@/assets/images/icon-wenyi-default.png"),
      wenyied: require("@/assets/images/icon-wenyi-select.png"),
      yuyan: require("@/assets/images/icon-yuyan-default.png"),
      yuyaned: require("@/assets/images/icon-yuyan-select.png"),
      zonghe: require("@/assets/images/icon-zonghe-default.png"),
      zongheed: require("@/assets/images/icon-zonghe-select.png"),
      classifyImg: require("@/assets/images/icon-class-default.png"),
      classifyImged: require("@/assets/images/icon-class-select.png"),
      path: "",
    };
  },
  watch: {
    $route: {
      handler(val) {
        this.path = val.name;
        this.token = getToken();
      },
    },
  },
  computed: {},
  created() {
    setTimeout(() => {
      if (getToken()) {
        this.getData();
      }
    }, 200);
  },
  mounted() {},
  methods: {
    toHome() {
      this.$router.push({
        path: "/",
      });
    },
    // 点击事件
    handleClick(val, name, index, idx, threeidx, fouridx) {
      this.$store.dispatch("setLeft", { subjectIds: name });
      let obj = { key: "subjectIds", value: [val.id] };
      this.$store.dispatch("updateField", obj);
      this.$router.push({
        path: "/classify",
      });
      this.classifyFlag = false;
      let indexArray = [index, idx, threeidx, fouridx];
      this.$store.commit("TOP_ARRAY", indexArray);
    },
    // 获取数据
    getData() {
      indexSubjectList().then(res => {
        if (res) {
          let treeData = handleTree(res.data);
          this.classifyList = treeData;
        }
      });
    },

    async logout() {
      if (getToken()) {
        this.$confirm("确定注销并退出系统吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$store.dispatch("LogOut").then(() => {
              this.$router.push({
                path: "/login",
                query: {
                  redirect: this.$route.fullPath,
                  loginFlag: 1,
                },
              });
            });
          })
          .catch(() => {});
      } else {
        this.$router.push({
          path: "/login",
          query: {
            redirect: this.$route.fullPath,
            loginFlag: 1,
          },
        });
      }
    },
    classifyHover() {
      this.classifyFlag = true;
    },
    classifyOut() {
      this.classifyFlag = false;
    },
    getImg(name, index) {
      if (name == "科技") {
        if (index == 1) {
          return this.kejied;
        } else {
          return this.keji;
        }
      }

      if (name == "教材") return this.jiaocai;
      if (name == "生活") return this.shenghuo;
      if (name == "少儿") return this.shaoer;
      if (name == "社科") return this.sheke;
      if (name == "文艺") return this.wenyi;
      if (name == "语言") return this.yuyan;
      if (name == "综合") return this.zonghe;
    },
    getImage(name, index) {
      if (name == "科技") {
        if (this.classifyList[index].hover) {
          return this.kejied;
        } else {
          return this.keji;
        }
      }
      if (name == "教材") {
        if (this.classifyList[index].hover) {
          return this.jiaocaied;
        } else {
          return this.jiaocai;
        }
      }
      if (name == "生活") {
        if (this.classifyList[index].hover) {
          return this.shenghuoed;
        } else {
          return this.shenghuo;
        }
      }
      if (name == "少儿") {
        if (this.classifyList[index].hover) {
          return this.shaoered;
        } else {
          return this.shaoer;
        }
      }
      if (name == "社科") {
        if (this.classifyList[index].hover) {
          return this.shekeed;
        } else {
          return this.sheke;
        }
      }
      if (name == "文艺") {
        if (this.classifyList[index].hover) {
          return this.wenyied;
        } else {
          return this.wenyi;
        }
      }
      if (name == "语言") {
        if (this.classifyList[index].hover) {
          return this.yuyaned;
        } else {
          return this.yuyan;
        }
      }
      if (name == "综合") {
        if (this.classifyList[index].hover) {
          return this.zongheed;
        } else {
          return this.zonghe;
        }
      }
    },
    changeImg(name, index) {
      this.classifyList[index].hover = true;
      this.getImage(name, index);
      this.$forceUpdate();
    },
    outImg(name, index) {
      this.classifyList[index].hover = false;
      this.getImage(name, index);
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #fff;
  color: #333;
  font-size: 18px;
  box-shadow: 0px 0px 20px 0px rgba(0, 98, 173, 0.46);
  .tcdiv {
    &:hover {
      color: #078bef;
    }
  }
  > .w1200 {
    height: 70px;
    align-items: center;
    // justify-content: center;
    .list {
      margin-left: auto;
      .checked {
        color: #078bef;
      }
      .flex {
        align-items: center;
      }
      img {
        margin-right: 5px;
        width: 18px;
        height: 18px;
      }
      .pointer {
        height: 70px;
        cursor: pointer;
        align-items: center;
      }
      .ml40 {
        img {
          width: 22px;
          height: 17px;
        }
      }
    }
  }
  .wapperbg {
    position: absolute;
    top: 70px;
    left: 0;
    z-index: 99;
    height: 420px;
    width: 100%;
  }
  .classify {
    position: absolute;
    top: 70px;
    left: 0;
    background: #fff;
    width: 100%;
    z-index: 9999;
    font-size: 16px;
    border-top: 1px solid #078bef;
    padding: 20px 0 55px;
    ul {
      position: relative;
    }
    .oneClass {
      align-items: center;
      border-right: 1px dashed #dedede;
      width: 150px;
      padding-bottom: 15px;
      cursor: pointer;
      &:hover {
        color: #078bef;
        .twoClass {
          display: inline-block;
        }
      }
      &:last-child {
        padding: 0;
      }
      img {
        width: 18px;
        max-height: 18px;
        margin-right: 15px;
      }
      .twoClass {
        display: none;
        position: absolute;
        left: 150px;
        top: 0;
        width: 180px;
        border-right: 1px dashed #dedede;
        color: #333;
        .twoClassContent {
          width: 180px;
          min-height: 100%;
          height: 310px;
          overflow: auto;
          scrollbar-width: none;
          .two-item {
            padding-left: 40px;
            padding-right: 30px;
            padding-bottom: 15px;
            &:last-child {
              padding-bottom: 0;
            }
            &:hover {
              color: #078bef;
              .threeClass {
                display: inline-block;
              }
            }
            .threeClass {
              display: none;
              position: absolute;
              left: 180px;
              top: 0;
              // padding-left: 40px;
              min-height: 100%;
              height: 320px;
              writing-mode: vertical-lr;
              // min-width: 600px;
              width: auto;
              color: #333;
              .flex {
                padding-left: 40px;
                flex-wrap: wrap;
                span {
                  float: left;
                  // width: 50px;
                  writing-mode: horizontal-tb;
                  margin-right: 30px;
                  display: inline-block;
                  margin-bottom: 15px;
                  &:hover {
                    color: #078bef;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.tsflspan {
  font-size: 18px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #fff;
  margin-left: 5px;
}
</style>
