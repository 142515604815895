<template>
  <div id="app" class="wrapper">
    <template v-if="$route.path == '/login'">
      <router-view class="view" />
    </template>
    <template v-else-if="$route.path == '/index' || $route.path == '/'">
      <Top></Top>
      <transition name="fade-transform" mode="out-in">
        <template v-if="$route.meta.keepAlive">
          <keep-alive>
            <router-view class="view" />
          </keep-alive>
        </template>
        <template v-else>
          <router-view class="view" />
        </template>
      </transition>
      <!-- <Bottom></Bottom> -->
    </template>
    <template v-else>
      <Top></Top>
      <transition name="fade-transform" mode="out-in">
        <template v-if="$route.meta.keepAlive">
          <keep-alive>
            <router-view class="view" />
          </keep-alive>
        </template>
        <template v-else>
          <router-view class="view" />
        </template>
      </transition>
      <Bottom></Bottom>
    </template>
  </div>
</template>
<script>
import Top from "@/components/top.vue";
import Bottom from "@/components/bottom.vue";

export default {
  components: {
    Top,
    Bottom,
  },
};
</script>
<style>
</style>
<style lang="scss">
#app {
  background: #edeef0;
  font-size: 16px;
  height: 100vh;
  width: 100vw;
}

/* fade */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.28s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-100px);
}
.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(100px);
}

// 全局通改elementui
// el-select
.el-select-dropdown__item {
  font-size: 16px !important;
}
.el-range-editor--medium .el-range-input {
  font-size: 16px;
}
/* 修改el-input高度 */
.el-input--medium .el-input__inner {
  height: 38px;
  line-height: 38px;
}
/* 修改form中文本高度 */
.el-form-item--medium .el-form-item__content {
  line-height: 38px;
}
/* 修改form中的label的line-height高度 */
.el-form-item--medium .el-form-item__label {
  line-height: 38px;
}
.el-form-item__content {
  line-height: 40px;
  position: relative;
  font-size: 16px;
}
/* 修改form中label字体样式 */
.el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 16px;
  color: #333333;
  line-height: 40px;
  padding: 0 12px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
/* 修改el-input中placeholder的样式 */
.el-input__inner::placeholder {
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #c0c4cc;
  line-height: 28px;
}
/* 谷歌 */
.el-input__inner::-webkit-input-placeholder {
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #c0c4cc;
  line-height: 36px;
}
/* 火狐 */
.el-input__inner:-moz-placeholder {
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #c0c4cc;
  line-height: 28px;
}
/*ie*/
.el-input__inner:-ms-input-placeholder {
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #c0c4cc;
  line-height: 28px;
}
/* 修改el-input内输入字体的样式 */
.el-input .el-input__inner {
  font-size: 16px;
}
/* button样式 */
.el-button--medium {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
}
.el-button--small {
  padding: 9px 15px;
  font-size: 16px;
  border-radius: 3px;
}
.el-button--text {
  padding: 10px 5px !important;
  border-color: transparent;
  color: #1890ff;
  background: transparent;
  padding-left: 0;
  padding-right: 0;
}
/* table样式 */
.el-table {
  font-size: 16px;
}
.el-table .el-table__header-wrapper th,
.el-table .el-table__fixed-header-wrapper th {
  word-break: break-word;
  background-color: #f8f8f9;
  color: #515a6e;
  height: 40px;
  font-size: 16px;
}
.el-table--medium .el-table__cell {
  padding: 13px 0;
}
/* 分页样式 */
.el-pagination span:not([class*="suffix"]),
.el-pagination button {
  display: inline-block;
  font-size: 16px;
  min-width: 35.5px;
  height: 28px;
  line-height: 28px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.el-pagination span:not([class*="suffix"]),
.el-pagination button {
  display: inline-block;
  font-size: 16px;
  min-width: 35.5px;
  height: 38px;
  line-height: 38px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.el-pagination__sizes .el-input .el-input__inner {
  font-size: 16px;
  padding-left: 8px;
}
.el-input--mini .el-input__inner {
  height: 38px;
  line-height: 38px;
}
.el-pager li {
  padding: 0 4px;
  background: #ffffff;
  vertical-align: top;
  display: inline-block;
  font-size: 16px;
  min-width: 35.5px;
  height: 38px;
  line-height: 38px;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  margin: 0;
}
.el-pager li.btn-quicknext,
.el-pager li.btn-quickprev {
  line-height: 38px;
  color: #303133;
}
.el-pagination {
  margin: 30px 0 50px !important;
}
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .btn-next,
.el-pagination.is-background .el-pager li {
  margin: 0 5px;
  background-color: #f4f4f5;
  color: #606266;
  min-width: 38px;
  border-radius: 2px;
}
/* confirm确认框 */
.el-message-box__content {
  padding: 10px 15px;
  color: #666666;
  font-size: 16px;
}
.el-message-box__title {
  padding-left: 0;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 1;
  color: #333333;
}
/* textarea样式 */
/* 修改el-input-textarea中placeholder的样式 */
.el-textarea__inner::placeholder {
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #c0c4cc;
  line-height: 28px;
}
/* 谷歌 */
.el-textarea__inner::-webkit-input-placeholder {
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #c0c4cc;
  line-height: 28px;
}
/* 火狐 */
.el-textarea__inner:-moz-placeholder {
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #c0c4cc;
  line-height: 28px;
}
/*ie*/
.el-textarea__inner:-ms-input-placeholder {
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #c0c4cc;
  line-height: 28px;
}
/* 修改el-input内输入字体的样式 */
.el-textarea__inner {
  font-size: 16px;
  color: #333333;
}
/* 修改el-menu */
.el-menu-item {
  font-size: 16px;
}
.el-submenu__title {
  font-size: 16px;
}
/* el-tabs样式 */
.el-tabs__item {
  font-size: 16px;
}
/* el-radio */
.el-radio__label {
  font-size: 16px;
  padding-left: 10px;
}
/* 修改el-checkbox */
.el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 16px;
}
/* el-tree */
.el-tree-node__label {
  font-size: 16px;
}
</style>
